import moment from 'moment';
import { MeetingParticipant } from './MeetingParticipant.resource';
import { TaskAssignment } from './TaskAssignments.resource';

export type TaskResourceStatus = 'not-started' | 'running' | 'finished';
export class TaskResource {
	id: number;
	title: string;
	startSecondsNeeded: number;
	secondsNeeded: number;
	secondsLeft: number;
	secondsCounted: number;
	overtimeSecondsCounted: number;
	duration: number;
	isRunning: boolean;
	color?: string;
	due?: Date;
	responsiblePerson?: MeetingParticipant;
	goal: string;
	assignments: TaskAssignment[];
	status: TaskResourceStatus;

	constructor(id: number, title: string, startSecondsNeeded: number, secondsNeeded: number, secondsLeft: number, secondsCounted: number, overtimeSecondsCounted: number, duration: number, isRunning: boolean, goal: string, assignments: TaskAssignment[], status: TaskResourceStatus) {
		this.id = id;
		this.title = title;
		this.startSecondsNeeded = startSecondsNeeded;
		this.secondsNeeded = secondsNeeded;
		this.secondsLeft = secondsLeft;
		this.secondsCounted = secondsCounted;
		this.overtimeSecondsCounted = overtimeSecondsCounted;
		this.duration = duration;
		this.isRunning = isRunning;
		this.goal = goal;
		this.assignments = assignments;
		this.status = status;
	}

	start(): void {
		this.due = moment() // Timestamp now
			.add(this.secondsLeft, 'seconds') // add seconds needed to timestamp
			.toDate();
	}

	stop(): void {
		this.secondsLeft = this.due ? this.due.getSeconds() - new Date().getSeconds() : this.secondsNeeded;
		this.due = undefined;
	}
}
