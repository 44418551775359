









































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { TaskResource } from '../classes/Task.resource';
import { WorkingPeriodResource } from '@/classes/WorkingPeriod.resource';

@Component({
	components: {
		BaseButton: () => import('../components/base/BaseButton.vue')
	}
})
export default class AgendaPointForm extends Vue {
	@Prop({ default: 1 })
	nextTaskId: number;

	@Prop({ default: {} })
	workingPeriod: WorkingPeriodResource;
	// Task Info
	public taskId = 0; //Task Id - used by createTask
	public taskName = ''; // task id start from 0
	public taskGoal = '';
	public taskTimeNeeded = 10;
	// Errors
	public taskNameError = false;
	public taskGoalError = false;
	public taskTimeNeededError = false;

	public displayList = false;
	public selectedTaskIndex = -1;
	//public selectedTaskId = 0;

	async mounted(): Promise<void> {
		this.taskId = this.nextTaskId; //Task Id - used by createTask
		this.taskName = 'Topic ' + (this.nextTaskId + 1); // task id start from 0
	}

	async validateTaskInput(): Promise<void> {
		this.taskName == '' ? (this.taskNameError = true) : (this.taskNameError = false);
		this.taskGoal == '' ? (this.taskGoalError = true) : (this.taskGoalError = false);
		this.taskTimeNeeded > 0 && this.taskTimeNeeded <= 999 && String(this.taskTimeNeeded).length > 0 ? (this.taskTimeNeededError = false) : (this.taskTimeNeededError = true);
		if (!this.taskNameError && !this.taskGoalError && !this.taskTimeNeededError) {
			await this.createTask();
		}
	}

	selectTask(task: TaskResource): void {
		let taskIndex = this.workingPeriod.tasks.findIndex((t) => t.id === task.id);
		console.log(taskIndex);
		if (this.selectedTaskIndex === taskIndex) {
			this.selectedTaskIndex = -1;
			console.log('test1');
		} else {
			this.selectedTaskIndex = taskIndex;
			console.log('test2');
		}
		this.displayList = false;
	}

	async openPanel(): Promise<void> {
		if (this.displayList === false) {
			this.displayList = true;
			setTimeout(() => {
				const list = document.getElementById('task-list');
				if (list) {
					list.focus();
				}
			}, 1);
		} else if (this.displayList === true) {
			this.displayList = false;
		}
	}

	async closePanel(): Promise<void> {
		setTimeout(() => {
			this.displayList = false;
		}, 110);
	}

	async createTask(): Promise<void> {
		const taskSeconds = Number(parseFloat(String(this.taskTimeNeeded)).toFixed(0)) * 60;
		if (this.taskName != '') {
			const task: TaskResource = {
				id: this.nextTaskId,
				title: this.taskName, //title
				startSecondsNeeded: taskSeconds, //startSecondsNeeded
				secondsNeeded: taskSeconds, //secondsNeeded
				secondsLeft: taskSeconds, //secondsLeft
				secondsCounted: 0, //secondsCounted
				overtimeSecondsCounted: 0, //secondsCounted
				duration: Number(parseFloat(String(this.taskTimeNeeded)).toFixed(0)), //duration
				isRunning: false, //isRunning
				goal: this.taskGoal, //goal
				color: '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0'),
				assignments: [],
				status: 'not-started',
				start: TaskResource.prototype.start,
				stop: TaskResource.prototype.stop
			};
			// send task
			console.log(this.selectedTaskIndex);
			const extraTaskObject = {
				task: task,
				position: this.selectedTaskIndex < 0 ? this.workingPeriod.tasks.length : this.selectedTaskIndex + 1
			};
			this.$emit('add-extra-task', extraTaskObject);
			// reset errors
			this.taskNameError = false;
			this.taskGoalError = false;
			this.taskTimeNeededError = false;
			// update form inputs
			this.taskName = 'Topic ' + this.nextTaskId;
			this.taskGoal = '';
			this.taskTimeNeeded = 10;
		}
	}

	get availableTasks(): TaskResource[] {
		const availableTasks: TaskResource[] = [];
		if (this.workingPeriod.status === 'not-started') {
			this.workingPeriod.tasks.forEach((task) => {
				availableTasks.push(task);
			});
		} else if (this.workingPeriod.status === 'running') {
			this.workingPeriod.tasks.forEach((task) => {
				if (task.status === 'not-started' || task.status === 'running') {
					availableTasks.push(task);
				}
			});
		} else {
			this.workingPeriod.tasks.forEach((task) => {
				availableTasks.push(task);
			});
		}

		return availableTasks;
	}
}
